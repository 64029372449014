<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Дабавить цвет машина" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="clearData">
            <b-row>
                <b-col cols="12 mb-1">
                    <label>Название</label>
                    <b-input v-model="colorType.name" placeholder="Название" />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12 mb-1">
                     <label>Название тч</label>
                    <b-input v-model.trim="colorType.name_tj" placeholder="Название тч" />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                     <label>Название для смс</label>
                    <b-input v-model.trim="colorType.name_for_sms" placeholder="Название для смс" />
                </b-col>
            </b-row>
            <b-row class="mb-1">
            </b-row>
            <b-row>
                <b-col>
                    <b-form-checkbox v-model.trim="colorType.is_active" class="custom-control-primary">
                        {{ colorType.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                        :disabled="submitButtonDisabled"
                        class='mt-2 col-md-5'
                        variant="primary"
                        @click="createBodyType">
                            Дабавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent
    },
    props: ['color'],
    data() {
        return {
            colorType:{
                name: null,
                name_tj: null,
                name_for_sms: null, 
                is_active: true,
            },
            submitButtonDisabled: false,
        }
    },
    methods: {
        clearData() {
            this.colorType = {
                name: null,
                name_tj: null,
                name_for_sms: null,
                is_active: true,
                }
            },
            closeModal() {
                this.$bvModal.hide(this.$route.name + 'CreateModal')
            },
            createBodyType()  {
                this.submitButtonDisabled = true
                this.$http.post('/car-settings/car-colors', this.colorType)
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Цветь автомобиля создан!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    this.$emit('refresh')
                    this.clearData()
                    this.closeModal()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные цвет!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors, 
                        },
                    })
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
            },
        }
    }
</script>