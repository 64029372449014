<template>
    <div>
       <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
      <b-spinner variant="primary" label="Text Centered" />     
        </div>
        <div v-else>
           <table-car-colors :options="carColors" @editForm="editForm"/>
        </div>
        <modal-car-color-type :color="color" @refresh="refresh"/>
        <modal-car-color-type-edit :color="color" @refresh="refresh"/>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter.js'
import ModalCarColorType from '@/views/component/Modal/ModalColorCar/ModalCarColorType.vue'
import ModalCarColorTypeEdit from '@/views/component/Modal/ModalColorCar/ModalCarColorTypeEdit.vue'
import tableCarColors from '@/views/component/Table/tableCar-colors.vue';
export default {
    components: {
        ModalCarColorType,
        ModalCarColorTypeEdit,
        tableCarColors,
    },
    data() {
        return {
            carColors:[],
            color: [],
            showPreloader: false,
            fields: [
                {key: 'id', label: 'ID'},
                {key: 'name', label: 'Название'},
                {key: 'is_active', label: 'Статус'},
                {key: 'date_time', label: 'Дата'},
            ],
        }
    },
    mounted() {
        this.openFilter()
        this.getCategories()
    },
    methods: {
        editForm(bodyType) {
            GlobalEventEmitter.$emit('openEditCarBodyTypeModal', bodyType)
        },
        getCategories() {
            this.$http.get('/car-settings/car-colors', {params: {is_active: true}})
            .then((res) => {
                this.color = res.data
            })
        },
        openFilter() {
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true){
                this.showPreloader = true
                this.$http
                .get(`${this.$route.name}`,{params: param}).then(res => {
                    this.carColors = res.data
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
            }else if(arrayRoutes.includes(this.$route.path) == false){
        this.$bvModal.show(this.$route.name + 'filter')
      }
        },
        refresh() {
            this.showPreloader = true
            this.$http
            .get('car-settings/car-colors')
            .then(res => {
                this.carColors = res.data
                this.showPreloader = false
                this.$store.commit('REFRESH_DATA', false)
            }).catch(err => {})
        },
        sendToParent(tableData) {
            this.carColors = tableData
        },
    },
    computed: {
       fetchingNewData() {
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val) {
            if(val) {
                let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                this.$http
                .get(`${this.$route.name}`, {param: params})
                .then(res =>{
                    this.carColors = res.data
                    this.$store.commit('REFRESH_DATA', false)
                })
            }
        }
    }
}
</script>